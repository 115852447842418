import React from "react";
import styles from "../../styles/Text.module.css";

const Text4 = () => {
  return (
    <div className={styles.Text4Wrapper}>
      <div>The best Adventurers have to say part?</div>
      <p>
        We’ll make it unforgettable with amazing experiences and smooth
        planning. Just relax and enjoy—let us handle everything!
      </p>
    </div>
  );
};

export default Text4;
